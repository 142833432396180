/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.custom-modal .modal-dialog {
  width: 75%;
  max-width: none;
  height: 100%;
}
.custom-modal .modal-dialog .modal-content {
  height: 75%;
  overflow: auto;
}


.ngb-dp-day {
  text-align: center;

  span {
    display: block;
    line-height: 30px;
    width: 100%;
  }
  span:hover {
    background-color: #f1b44c62;
  }
  span.focused {
    background-color: #f1b44c9c;
  }
}